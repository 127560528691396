import React, { PureComponent } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  MortgagesHero,

} from 'components/sections';
import { TripleImageSection } from 'components/blocks';
import buyPageMotion from 'motion/buy.js';

import { ViewportEnterAnimation } from 'components/ui';
import { functionSinglePropertyAdvice, functionSlider, tripleImageSectionScroll } from 'motion/buy.js';
import { indexScrollState} from 'motion/index.js';
import { functionAboutAccordion } from 'motion/about.js';

indexScrollState();


export default class MortgagesPage extends PureComponent {
  componentDidMount() {
    const enquiryType =
      this.props.data.pages.propertySearchForm.offerTypes.sales
      || 'Sales';

    // Set enquiry type to Sales on /buy
    if (typeof window !== 'undefined') {
      window.directStateMutate({
        enquiryType,
      });
    }
  }

  ctaClick = () => {
    if (typeof window !== 'undefined') {
      window.openEnquiry && window.openEnquiry();
    }
  }

  render() {
    const { data } = this.props;
    const {
      pages: { propertySearchForm, buy },
    } = data;
    const { pageTitle, faq, landing, pitch, developments, callToAction, seo} = buy;

    return (
      <Layout onVisible={buyPageMotion}>
         <SEO title={seo.title} description={seo.meta} keywords={seo.keywords} />

    
        <MortgagesHero content={landing} propertySearchForm={propertySearchForm} />


      </Layout>
    );
  }
}

export const query = graphql`
  query MortgagesPage {
    pages {
      propertySearchForm {
        title
        offerTypes {
          lettings
          sales
        }
        defaultCost {
          min
          max
        }
        form {
          labels {
            area
            apartment
            bedrooms
            includeNewHomes
            searchBtn
          }
          selects {
            apartment {
              value
              text
            }
            bedrooms {
              value
              text
            }
          }
        }
      }
      buy {
        pageTitle
        landing {
          title
          bodyText
          img
        }
        seo{
          title
          keywords
          meta
        }
      }
    }
  }
`;
